@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"
































































































































.result-card
  padding: 32px 40px
  background-repeat: no-repeat, repeat
  background-position: right
  justify-content: none
  background-image: url(~@/assets/exam-illustration.svg)
  background-position-y: 48px

  +mq-l
    padding: 32px
    background-position-y: calc(100% + 48px)
    background-position-x: calc(100% + 64px)
    background-size: 320px

  +mq-s
    padding: 24px
    background-size: 240px

  +mq-xs
    padding: 16px
    background-position-y: calc(100% + 8px)
    background-size: 180px

  .card
    justify-content: none

  .details__result
    margin-top: 40px
    margin-bottom: 16px
    width: 512px

    +mq-l
      grid-gap: 24px
      width: auto
      grid-template-columns: 1fr

    +mq-xs
      margin-top: 32px

    &.__classic-score
      display: flex
      margin-right: 88px
      justify-content: space-between

    .info-right__label
      font-size: 16px

      +mq-s
        font-size: 13px

    .result__score
      display: inline-flex

      h1
        font-size: 96px
        line-height: 1
        color: $color-ink

        +mq-l
          font-size: 80px

        +mq-s
          font-size: 56px

      .score__base
        color: $color-ink-light
        align-self: flex-end

        +mq-s
          font-size: 18px

    .result__hits
      align-self: flex-start

  .details__warning
    &.--mobile
      display: none

      +mq-l
        display: grid

    +mq-l
      display: none
      margin-top: 16px
      grid-template-columns: $size-m 1fr

    .info-right__label
      font-size: 16px

      +mq-s
        font-size: 13px
.result
  display: flex
  flex-direction: row
  font-size: 1.5rem
