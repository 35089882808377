@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




















































.card
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: space-between
  border-radius: 8px
  backface-visibility: hidden
  box-shadow: 0 1px 3px rgba(0,0,0,.25)
  box-sizing: border-box
  padding: 24px
  background: white
  min-height: 216px
  position: relative
  height: 100%
  color: $color-ink

  .card__header
    width: 100%
    box-sizing: border-box
    justify-content: space-between

  .card__action
    margin: 16px 0 0 0
    position: relative
    bottom: 0

